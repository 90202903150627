:root {
  --theme-color--error: red;

  --theme-color--header: var(--theme-color--primary);
  --theme-color--headerBorder: rgb(200, 200, 200);
  --theme-color--headerMenu: #fafafa;
  --theme-color--headerMenuButton: #09003e;
  --theme-color--headerMenuButtonBorder: #323232;
  --theme-color--headerMenuButtonBar: #ffffff;
  --theme-color--headerMenuItem: #fff;
  --theme-color--headerMenuItemBorder: #d2d2e6;

  --theme-color--listItemBorder: #c8c8c8;

  --theme-color--input: #fafafa;
  --theme-color--inputIcon: #ffffff;
  --theme-color--inputBorder: #d2d2e6;

  --theme-color--primary: #13007c;
  --theme-color--primaryContrast: #ffffff;

  --theme-color--section: #ffffff;
  --theme-color--sectionBorder: #c8c8c8;

  --theme-height--svh: 100svh;
  --theme-height--header: 5rem;
  --theme-height--svh-header: calc(
    var(--theme-height--svh) - var(--theme-height--header)
  );
}
body {
  color: #282828;
  background-color: rgb(245, 250, 255);
  font-family: "Lexend", sans-serif;
}
body::-webkit-scrollbar {
  width: 0;
  height: 0;
}
iframe {
  width: 100%;
  height: 100%;
}
